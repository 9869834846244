import './App.css';
import * as React from 'react';
import Util from "./Util/Util";
import Login from "./Login/Login";
import Main from "./Main/Main";
import axios from "axios";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Component} from "react";

export default class App extends Component {

	constructor(props) {
		super(props);
		this.state = {isLoaded: false, logged: false, data: {}}

		this.loginHandler = this.loginHandler.bind(this);
		this.loadPatients = this.loadPatients.bind(this);
	}

	componentDidMount() {
		axios.interceptors.response.use(response => {
			return response;
		}, error => {
			return error.response;
		});

		this.loadPatients();
	}

	loginHandler(logged) {
		this.setState({isLoaded: false, logged: false});
		this.loadPatients();
	}

	render() {
		let {isLoaded, logged, data} = this.state;
		if (!isLoaded)
			return Util.renderLoading();
		if (!logged)
			return <Login loginHandler={this.loginHandler}/>

		return <Main data={data}/>
	}

	loadPatients() {
		Util.loadData(undefined, res => {
			if (res === undefined)
				this.setState({error: "Empty response from server, contact the admin."});
			else if (res.status === 401)
				this.setState({logged: false, isLoaded: true});
			else if (res.status === 200)
				this.setState({logged: true, isLoaded: true, data: res.data});
		});
	}
}