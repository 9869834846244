import React, {Component} from "react";
import {
	Box,
	Chip,
	createTheme,
	CssBaseline,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from "@mui/material";
import Constants from "../../../Util/Constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
const theme = createTheme();

export default class BackgroundDataModalForm extends Component {

	constructor(props) {
		super(props);
		let justBornBackground = [], familyBackground = [];
		Constants.BACKGROUND_NAMES.forEach(x => {
			if (props.patient[x.key])
				justBornBackground.push(x.key)
		});
		Constants.FAMILIAR_BACKGROUNDS.forEach(x => {
			if (props.patient[x.key])
				familyBackground.push(x.key)
		})
		this.state = {
			patient: props.patient,
			justBornBackground: props.background[Constants.JUST_BORN] ? props.background[Constants.JUST_BORN] : justBornBackground,
			familyBackground: props.background[Constants.FAMILY] ? props.background[Constants.FAMILY] : familyBackground
		};
	}

	render() {
		let {patient, justBornBackground, familyBackground} = this.state;
		return (
			<Box sx={{flexGrow: 1}}>
				<CssBaseline/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl margin="normal" fullWidth size="small" sx={{marginBottom: 0}}>
							<InputLabel id="just-born-chip-label">Recien nacido</InputLabel>
							<Select
								labelId="just-born-chip-label"
								id="just-born-chip" multiple value={justBornBackground} autoFocus={true}
								onChange={this.handleJustBornChange}
								input={<OutlinedInput id="just-born-select-multiple-chip" label="Recien nacido"/>}
								renderValue={(selected) => (
									<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
										{selected.map((key) => (
											<Chip key={key}
											      label={Constants.BACKGROUND_NAMES.find(x => x.key === key).value}/>
										))}
									</Box>
								)}
								MenuProps={MenuProps}
							>
								{Constants.BACKGROUND_NAMES.map(({value, key}) => (
									<MenuItem key={key} value={key}
									          style={this.getStyles(value, justBornBackground, theme)}>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<TextField id="just_born_others" variant="outlined" label="Otros"
						           value={patient.just_born_others} margin="none" fullWidth
						           size={"small"} onChange={this.updateInputValue}/>
					</Grid>

					<Grid item xs={12}>
						<FormControl margin="none" fullWidth size="small">
							<InputLabel id="family-background-multiple-chip-label">Antecedentes familiares</InputLabel>
							<Select
								labelId="family-background-chip-label" id="family-background-multiple-chip"
								multiple value={familyBackground} onChange={this.handleFamilyBackgroundChange}
								input={<OutlinedInput id="family-background-select-multiple-chip"
								                      label="Antecedentes familiares"/>}
								renderValue={(selected) => (
									<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
										{selected.map((key) => (
											<Chip key={key}
											      label={Constants.FAMILIAR_BACKGROUNDS.find(x => x.key === key).value}/>
										))}
									</Box>
								)}
								MenuProps={MenuProps}
							>
								{Constants.FAMILIAR_BACKGROUNDS.map(({value, key}) => (
									<MenuItem key={key} value={key}
									          style={this.getStyles(value, Constants.FAMILIAR_BACKGROUNDS, theme)}>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={12}>
						<TextField id="others" variant="outlined" label="Otros antecedentes familiares"
						           value={patient.others} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>

					<Grid item xs={4}>
						<TextField id="months_pregnant" variant="outlined" label="Gesta" type="number"
						           value={patient.months_pregnant} margin="none" fullWidth
						           size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={4}>
						<TextField id="cesarea" variant="outlined" label="Cesarea" type="number"
						           value={patient.cesarea} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={4}>
						<TextField id="gave_birth" variant="outlined" label="Parto" type="number"
						           value={patient.gave_birth} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>

					<Grid item xs={4}>
						<TextField id="abortion" variant="outlined" label="Aborto" type="number"
						           value={patient.abortion} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={4}>
						<TextField id="weight" variant="outlined" label="Peso" type="number"
						           value={patient.weight} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={4}>
						<TextField id="apgar1" variant="outlined" label="Apgar 1'" type="number"
						           value={patient.apgar1} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>

					<Grid item xs={4}>
						<TextField id="apgar5" variant="outlined" label="Apgar 5'" type="number"
						           value={patient.apgar5} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={4}>
						<TextField id="size" variant="outlined" label="Talla" type="number"
						           value={patient.size} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={4}>
						<TextField id="cefalic_perimeter" variant="outlined" label="Perimetro cefalico" type="number"
						           value={patient.cefalic_perimeter} margin="none" fullWidth
						           size={"small"} onChange={this.updateInputValue}/>
					</Grid>

					<Grid item xs={4}>
						<TextField id="gest_age" variant="outlined" label="Edad gestacional" type="number"
						           value={patient.gest_age} margin="none" fullWidth size={"small"}
						           onChange={this.updateInputValue}/>
					</Grid>
					<Grid item xs={8}>
						<TextField id="just_born_patology" variant="outlined" label="Patologia del recien nacido"
						           value={patient.just_born_patology} margin="none" fullWidth
						           size={"small"} onChange={this.updateInputValue}/>
					</Grid>

					<Grid item xs={12}>
						<TextField id="congenital_errors" variant="outlined" label="Errores congenitos"
						           value={patient.congenital_errors} margin="none" fullWidth
						           size={"small"} onChange={this.updateInputValue}/>
					</Grid>
				</Grid>
			</Box>
		);
	}

	updateInputValue = (event) => {
		this.props.patient[event.target.id] = event.target.value;
		this.setState({patient: this.props.patient});
	}

	handleJustBornChange = (event) => {
		Constants.BACKGROUND_NAMES.forEach(x => delete this.props.patient[x.key]);
		this.props.background[Constants.JUST_BORN] = event.target.value;
		this.setState({justBornBackground: event.target.value});
	}

	handleFamilyBackgroundChange = (event) => {
		Constants.FAMILIAR_BACKGROUNDS.forEach(x => delete this.props.patient[x.key]);
		this.props.background[Constants.FAMILY] = event.target.value;
		this.setState({familyBackground: event.target.value});
	}

	getStyles = (name, personName, theme) => {
		return {
			fontWeight:
				personName.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}
}