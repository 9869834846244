import React, {Component} from "react";
import PatientRow from "./PatientRow";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export default class Grid extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    render() {
        let {patients} = this.props;
        const patientRows = patients.map((patient, index) => {
            return <PatientRow key={index} patient={patient} onConsultOpen={this.onConsultOpen}
                               onPatientInfoOpen={this.onPatientInfoOpen} onGraphOpen={this.onGraphOpen}/>
        });

        return (
            <TableContainer>
                <Table sx={{minWidth: 650}} aria-label="sticky table" size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{width: {xs: '5%', sm: '13%'}}}>Opciones</TableCell>
                            <TableCell sx={{width: {xs: '30%', sm: '40%'}}}>Apellido y nombre</TableCell>
                            <TableCell align="center" sx={{display: {xs: 'none', sm: 'revert'}}}>Direccion</TableCell>
                            <TableCell align="center">Fecha de nacimiento</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {patientRows}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    onConsultOpen = (patient) => {
        this.props.onConsultOpen(patient);
    }

    onPatientInfoOpen = (patient) => {
        this.props.onPatientInfoOpen(patient);
    }

    onGraphOpen = (patient) => {
        this.props.onGraphOpen(patient);
    }
}