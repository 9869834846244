import React, {Component} from "react";
import {Box, CssBaseline, FormControl, Grid, TextField} from "@mui/material";
import "moment/locale/es";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

export default class ParentsModalForm extends Component {

	constructor(props) {
		super(props);
		let {patient} = props;
		this.state = {
			fatherBirthDate: patient.fatherBirthDate || null,
			motherBirthDate: patient.motherBirthDate || null,
			patient: patient
		};
	}

	render() {
		let {patient, fatherBirthDate, motherBirthDate} = this.state;
		return (
			<Box sx={{flexGrow: 1}}>
				<CssBaseline/>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField id="fatherName" variant="outlined" label="Nombre del padre"
						           value={patient.fatherName} autoFocus={true} margin="normal" fullWidth
						           onChange={this.updateInputValue} size={"small"}/>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth margin="normal" size="small">
							<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
								<DatePicker label="Fecha de nacimiento del padre" maxDate={new Date()}
								            value={fatherBirthDate} onChange={(v) => {
									patient.fatherBirthDate = v;
									this.setState({fatherBirthDate: patient.fatherBirthDate});
								}} renderInput={(params) => <TextField size="small" {...params} />}/>
							</LocalizationProvider>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<TextField id="motherName" variant="outlined" label="Nombre de la madre"
						           value={patient.motherName === null ? "" : patient.motherName} margin="none" fullWidth
						           onChange={this.updateInputValue} size={"small"}/>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth margin="none" size="small">
							<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
								<DatePicker label="Fecha de nacimiento de la madre" maxDate={new Date()}
								            value={motherBirthDate} onChange={(v) => {
									patient.motherBirthDate = v;
									this.setState({motherBirthDate: patient.motherBirthDate});
								}} renderInput={(params) => <TextField size="small" {...params} />}/>
							</LocalizationProvider>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
		);
	}

	updateInputValue = (event) => {
		if (event.target.value === null)
			return;
		this.props.patient[event.target.id] = event.target.value;
		this.setState({patient: this.props.patient});
	}
}