import React, {Component} from "react";
import {IconButton, TableCell, TableRow} from "@mui/material";
import {
    ListAltOutlined,
    Person2Outlined,
    TrendingUpOutlined
} from "@mui/icons-material";

export default class PatientRow extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    render() {
        let {patient} = this.props;
        let date = new Intl.DateTimeFormat('es', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(new Date(patient.birthDate));
        let address = patient.address + ", " + patient.city;
        address = address.includes("null") ? patient.city : address;
        return (
            <React.Fragment>
                <TableRow key={patient.id} sx={{'& > *': {borderBottom: 'unset'}}}>
                    <TableCell align="center">
                        <IconButton onClick={() => this.onPatientInfoOpen(patient)}>
                            <Person2Outlined/>
                        </IconButton>
                        <IconButton onClick={() => this.onConsultOpen(patient)}>
                            <ListAltOutlined/>
                        </IconButton>
                        <IconButton onClick={() => this.onGraphsOpen(patient)}>
                            <TrendingUpOutlined/>
                        </IconButton>
                    </TableCell>
                    <TableCell>{patient.name}</TableCell>
                    <TableCell align="center" sx={{display: {xs: 'none', sm: 'revert'}}}>{address}</TableCell>
                    <TableCell align="center">{date}</TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    onConsultOpen = (patient) => {
        this.props.onConsultOpen(patient);
    }

    onPatientInfoOpen = (patient) => {
        this.props.onPatientInfoOpen(patient);
    }

    onGraphsOpen = (patient) => {
        this.props.onGraphOpen(patient);
    }
}