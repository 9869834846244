import React, {Component} from "react";
import Config from "../Config"
import axios from "axios";
import "./Main.css"
import Util from "../Util/Util";
import Grid from "./Grid/Grid";
import {
    Alert,
    alpha,
    AppBar,
    IconButton,
    InputBase,
    Snackbar,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    styled,
    Toolbar,
    Typography
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {ExitToApp, FirstPage, LastPage, NavigateBefore, NavigateNext, PersonAddAlt1} from "@mui/icons-material";
import NewPatientModal from "./Modal/Patient/NewPatientModal";
import ConsultModal from "./Modal/Consult/ConsultModal";
import GraphModal from "./Modal/Patient/Graph/GraphModal";

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const drawerWidth = 240;

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default class Main extends Component {

    delayTimer;

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            loading: false,
            isDrawerOpened: false,
            openNewPatientModal: false,
            openNotification: false,
            openConsultModal: false,
            selectedPatient: {},
            alertMessage: '',
            openPatientInfoModal: false,
            openGraphModal: false
        }
        this.logout = this.logout.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);

        this.closeNewPatientModal = this.closeNewPatientModal.bind(this);
    }

    render() {
        let {
            data,
            loading,
            isDrawerOpened,
            openNewPatientModal,
            openNotification,
            alertMessage,
            openConsultModal,
            selectedPatient,
            openPatientInfoModal,
            openGraphModal
        } = this.state;
        data._embedded = data._embedded || {patientList: []};
        return (
            <div style={{background: '#f8f9fa'}} className="d-flex flex-column overflow-hidden min-vh-100 vh-100"
                 tabIndex={"0"}>
                {/*
					<Drawer
					sx={{
						width: drawerWidth, flexShrink: 0,
						'& .MuiDrawer-paper': {width: drawerWidth, boxSizing: 'border-box',},
					}}
					anchor="left" open={isDrawerOpened} onClose={this.handleDrawerClose}>
					<DrawerHeader>
						<IconButton onClick={this.handleDrawerClose}>
							<ChevronLeftIcon/>
						</IconButton>
					</DrawerHeader>
					<Divider/>
					<List>
						<ListItem key="new-patient" disablePadding>
							<ListItemButton onClick={() => this.showNewPatientModal()}>
								<ListItemIcon>
									<PersonAddAlt1/>
								</ListItemIcon>
								<ListItemText primary="Agregar paciente"/>
							</ListItemButton>
						</ListItem>
					</List>
				</Drawer>
				*/}
                <header>
                    <AppBar position="static">
                        <Toolbar>
                            {/*<IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawerOpen}
							            sx={{mr: 2, ...(isDrawerOpened && {display: 'none'})}}>
								<MenuIcon/>
							</IconButton>*/}
                            <Typography variant="h6" noWrap component="div"
                                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}} className="unselectable">
                                Pediatria
                            </Typography>
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon/>
                                </SearchIconWrapper>
                                <StyledInputBase placeholder="Buscar..." inputProps={{'aria-label': 'search'}}
                                                 onKeyUp={this.doSearch}/>
                            </Search>
                            <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ml: 2}}
                                        onClick={this.logout}>
                                <ExitToApp/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </header>
                <main role="main" style={{overflowY: 'auto', flex: 1, display: 'flex'}}>
                    {loading ? Util.renderLoading() :
                        <Grid patients={data._embedded.patientList} onConsultOpen={this.onConsultOpen}
                              onPatientInfoOpen={this.onPatientInfoOpen} onGraphOpen={this.onGraphOpen}/>}
                </main>
                <footer style={{height: '3rem', display: 'flex'}}>
                    <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0}}>
                        <Toolbar sx={{margin: 'auto'}} variant="dense">
                            <IconButton color="inherit" disabled={!data._links.first}
                                        onClick={() => this.loadData(data._links.first.href)}>
                                <FirstPage/>
                            </IconButton>
                            <IconButton color="inherit" disabled={!data._links.prev}
                                        onClick={() => this.loadData(data._links.prev.href)}>
                                <NavigateBefore/>
                            </IconButton>
                            <IconButton color="inherit" disabled={!data._links.next}
                                        onClick={() => this.loadData(data._links.next.href)}>
                                <NavigateNext/>
                            </IconButton>
                            <IconButton color="inherit" disabled={!data._links.last}
                                        onClick={() => this.loadData(data._links.last.href)}>
                                <LastPage/>
                            </IconButton>
                            <Typography noWrap sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
                                Página {data.page.number + 1} de {data.page.totalPages}
                            </Typography>
                        </Toolbar>

                        <SpeedDial ariaLabel="Agregar"
                                   FabProps={{sx: {bgcolor: 'secondary.main', '&:hover': {bgcolor: 'secondary.main',}}}}
                                   sx={{position: 'absolute', bottom: 16, right: 16}} icon={<SpeedDialIcon/>}>
                            <SpeedDialAction key="add-patient" icon={<PersonAddAlt1/>} tooltipTitle="Agregar paciente"
                                             onClick={() => this.showNewPatientModal()} title={"Agregar paciente"}/>
                        </SpeedDial>
                    </AppBar>
                </footer>

                <NewPatientModal open={openNewPatientModal} closeModal={this.closeNewPatientModal}/>
                <NewPatientModal open={openPatientInfoModal} patient={selectedPatient}
                                 closeModal={this.closePatientInfoModal}/>
                <ConsultModal open={openConsultModal} patient={selectedPatient} closeModal={this.closeConsultModal}/>
                <GraphModal open={openGraphModal} patient={selectedPatient} closeModal={this.closeGraphModal}/>
                <Snackbar open={openNotification} autoHideDuration={6000} onClose={this.handleNotificationClose}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={this.handleNotificationClose} severity="success" sx={{width: '100%'}}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }

    loadData(url) {
        this.setState({loading: true});
        Util.loadData(url, res => {
            if (res === undefined)
                this.setState({error: "Empty response from server, contact the admin."});
            else if (res.status === 401)
                this.logout();
            else if (res.status === 200)
                this.setState({data: res.data});
            this.setState({loading: false});
        });
    }

    doSearch(e) {
        this.setState({loading: true});
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            let url = Config.SEARCH_PATIENTS_API.replace("%text%", e.target.value);
            url = e.target.value === "" ? Config.LOAD_PATIENTS_API : url;
            this.loadData(url);
            clearTimeout(this.delayTimer);
        }, 650); // Will do the ajax stuff after 650 ms
    }

    logout() {
        axios.get(Config.LOGOUT_API, Config.AXIOS_CONFIG).then(() => window.location.href = "/");
    }

    handleDrawerOpen() {
        this.setState({isDrawerOpened: true});
    }

    handleDrawerClose() {
        this.setState({isDrawerOpened: false});
    }

    showNewPatientModal() {
        this.setState({isDrawerOpened: false, openNewPatientModal: true});
    }

    closeNewPatientModal(notificationMessage) {
        this.setState({openNewPatientModal: false});
        if (notificationMessage && notificationMessage !== '') {
            this.setState({openNotification: true, alertMessage: notificationMessage});
            this.loadData();
        }
    }

    closePatientInfoModal = (notificationMessage) => {
        this.setState({openPatientInfoModal: false});
        if (notificationMessage && notificationMessage !== '') {
            this.setState({openNotification: true, alertMessage: notificationMessage});
            this.loadData();
        }
    }

    handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        this.setState({openNotification: false});
    };

    onConsultOpen = (patient) => {
        this.setState({openConsultModal: true, selectedPatient: patient});
    }

    onPatientInfoOpen = (patient) => {
        this.setState({openPatientInfoModal: true, selectedPatient: patient});
    }

    onGraphOpen = (patient) => {
        this.setState({openGraphModal: true, selectedPatient: patient});
    }

    closeConsultModal = (notificationText) => {
        this.setState({
            openConsultModal: false,
            openNotification: !(notificationText === undefined || notificationText === ""),
            alertMessage: notificationText
        });
    }

    closeGraphModal = () => {
        this.setState({openGraphModal: false});
    }
}