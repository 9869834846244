import React, {Component} from "react";
import {
	Box,
	Button,
	CssBaseline,
	Divider,
	Grid,
	List,
	ListItemButton,
	ListItemText,
	ListSubheader,
	Modal,
	Paper,
	styled,
	TextField
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Util from "../../../Util/Util";
import Config from "../../../Config";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	//width: 800,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	outline: 0,
	width: '100%',
	maxWidth: '700px',
	gridTemplateColumns: {md: '1fr 1fr'},
	gap: 2,
};

const Item = styled(Paper)(({theme}) => ({
	...theme.typography.body2,
	textAlign: 'center',
	color: theme.palette.text.secondary,
	lineHeight: '60px',
	height: '40vh'
}));

export default class ConsultModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			consults: [],
			selectedConsultIndex: 0,
			selectedConsult: {},
			loading: false,
			error: false,
			consultOpened: false,
			firstOpened: true,
			disableForm: false
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let {patient} = this.props;
		if (patient === undefined || !this.state.firstOpened)
			return;

		if (prevProps.patient === undefined || (this.props.open && this.state.firstOpened)) {
			this.setState({loading: true, firstOpened: false});
			Util.loadData(Config.LOAD_CONSULT_API + patient.id, result => {
				let consults = result.data;
				consults.forEach(c => c.opened = false);
				this.setState({consults, selectedConsult: result.data[0], loading: false});
			});
		}
	}

	render() {
		let {open, patient} = this.props;
		let {consults, selectedConsultIndex, loading, error, consultOpened, disableForm} = this.state;

		let consultItems = consults.map((consult, index) => {
			return <ListItemButton selected={selectedConsultIndex === index} key={index}
			                       onClick={() => this.handleConsultListClick(index)}>
				<ListItemText primary={new Date(consult.date).toLocaleDateString('es')} sx={{
					textAlign: 'center',
					color: consult.opened ? 'green' : 'black'
				}}/>
			</ListItemButton>;
		});

		let weightValue = this.getValue("weight");
		let sizeValue = this.getValue("size");
		let clinicEvolutionValue = this.getValue("clinicEvolution");
		let pencefValue = this.getValue("pencef");
		let imcValue = Math.round((weightValue / 1000) / ((sizeValue / 100 * sizeValue / 100)) * 100) / 100;
		imcValue = isNaN(imcValue) || imcValue === Infinity ? 0 : imcValue;
		let taUpper = this.getValue("taupper");
		let taLower = this.getValue("talower");

		return (
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
			       onClose={this.onClose} closeAfterTransition BackdropComponent={Backdrop}
			       BackdropProps={{timeout: 500}}>
				<Box sx={style}>
					<CssBaseline/>
					<Box sx={{display: 'flex', flexDirection: 'row', pb: 2, justifyContent: 'center'}}>
						{patient.name + " - " + patient.fullAge}
					</Box>
					<Divider/>
					{loading &&
						Util.renderLoading()
					}
					{!loading &&
						<React.Fragment>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<Item elevation={3}>
										<List component="nav" aria-label="main mailbox folders"
										      sx={{height: '100%', overflow: 'auto'}}
										      subheader={
											      <ListSubheader component="div" id="nested-list-subheader"
											                     sx={{borderBottom: '1px solid lightgray'}}>
												      Consultas
											      </ListSubheader>
										      }>
											{consultItems}
										</List>
									</Item>
								</Grid>
								<Grid item xs={8}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField id="clinicEvolution" variant="outlined" fullWidth multiline
											           rows={5} disabled={disableForm}
											           onChange={this.handleOnChange} autoFocus={true} error={error}
											           label="Evolución clínica" value={clinicEvolutionValue}
											           inputProps={{readOnly: !consultOpened,}}/>
										</Grid>
										<Grid item xs={6}>
											<TextField id="weight" variant="outlined" label="Peso (gr)" size={"small"}
											           fullWidth onChange={this.handleOnChange} value={weightValue}
											           type="number" inputProps={{readOnly: !consultOpened}}
											           disabled={disableForm}/>
										</Grid>
										<Grid item xs={6}>
											<TextField id="size" variant="outlined" label="Talla (cm)" size={"small"}
											           fullWidth onChange={this.handleOnChange} type="number"
											           value={sizeValue.toString()} disabled={disableForm}
											           inputProps={{readOnly: !consultOpened}}/>
										</Grid>
										<Grid item xs={6}>
											<TextField id="pencef" variant="outlined" label="P. encefalico (cm)"
											           size={"small"} type="number" disabled={disableForm}
											           inputProps={{readOnly: !consultOpened}}
											           fullWidth onChange={this.handleOnChange} value={pencefValue}/>
										</Grid>
										<Grid item xs={6}>
											<TextField id="imc" variant="outlined" label="IMC" size={"small"}
											           fullWidth disabled value={imcValue} disabled={disableForm}
											           inputProps={{readOnly: !consultOpened}}/>
										</Grid>
										<Grid item xs={6}>
											<TextField id="taupper" variant="outlined" label="T.A. Alta" size={"small"}
											           fullWidth value={taUpper} onChange={this.handleOnChange}
											           type="number" inputProps={{readOnly: !consultOpened}}
											           disabled={disableForm}/>
										</Grid>
										<Grid item xs={6}>
											<TextField id="talower" variant="outlined" label="T.A. Baja" size={"small"}
											           fullWidth value={taLower} onChange={this.handleOnChange}
											           type="number" inputProps={{readOnly: !consultOpened}}
											           disabled={disableForm}/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Divider/>
							<Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
								<Button color="error" onClick={this.onClose} sx={{mr: 1}} disabled={disableForm}>
									Cancelar
								</Button>
								<Box sx={{flex: '1 1 auto'}}/>
								<Button onClick={() => this.addNewConsult(patient)} color="success"
								        disabled={disableForm}>
									Agregar consulta
								</Button>
								<Box sx={{flex: '1 1 auto'}}/>
								<Button onClick={this.handleSave} disabled={disableForm}>
									Guardar
								</Button>
							</Box>
						</React.Fragment>
					}
				</Box>
			</Modal>
		);
	}

	handleSave = () => {
		let {consults} = this.state;
		let {patient} = this.props;

		let processedConsults = consults.filter(c => c.id === undefined);
		if (processedConsults.length === 0)
			return;

		processedConsults.forEach(c => {
			c.patient = patient;
			c.opened = false;
		});
		this.setState({disableForm: true});
		Util.saveConsults(processedConsults, res => {
			if (res.data.status === 500) {
				this.setState({error: true});
			} else if (res.status === 200) {
				this.onClose(this, "closing_modal", "La consulta se guardo con exito.");
			}
			this.setState({disableForm: false});
		});
	}

	handleOnChange = (event) => {
		let {selectedConsult} = this.state;

		if (event.target.id === "clinicEvolution")
			this.setState({error: false});

		selectedConsult[event.target.id] = event.target.value;
		this.setState({selectedConsult});
	}

	handleConsultListClick = (selectedConsultIndex) => {
		let selectedConsult = this.state.consults[selectedConsultIndex];
		let consultOpened = selectedConsult.opened === undefined ? true : selectedConsult.opened;
		this.setState({selectedConsultIndex, selectedConsult, consultOpened});
	}

	getValue = (prop) => {
		let {selectedConsult} = this.state;
		if (selectedConsult === undefined)
			return "";
		return selectedConsult[prop] === undefined || selectedConsult[prop] === null ? "" : selectedConsult[prop];
	}

	onClose = (event, reason, notificationText) => {
		if (reason && reason === "backdropClick")
			return;

		this.setState({selectedConsult: {}, selectedConsultIndex: 0, consults: [], firstOpened: true});
		this.props.closeModal(notificationText);
	}

	addNewConsult = (patient) => {
		let {consults} = this.state;
		let newConsult = {date: new Date(), opened: true, clinicEvolution: patient.fullAge + "\n"};

		consults.unshift(newConsult); //add element at index 0
		this.setState({consults, selectedConsult: newConsult, selectedConsultIndex: 0, consultOpened: true});
	}
}