import React from 'react';
import axios from "axios";
import Config from "../Config"

export default class Util {
	static renderLoading() {
		return (
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="loader">
							<span/>
							<span/>
							<span/>
							<span/>
							<span/>
							<span/>
							<span/>
							<span/>
							<span/>
							<span/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	static loadData(url, result) {
		axios.get(url || Config.LOAD_PATIENTS_API, Config.AXIOS_CONFIG).then(result);
	}

	static savePatient(patient, result) {
		axios.post(Config.SAVE_PATIENT_API, patient, Config.AXIOS_CONFIG).then(result);
	}

	static saveConsults(consults, result) {
		axios.post(Config.SAVE_CONSULTS_API, consults, Config.AXIOS_CONFIG).then(result);
	}
}