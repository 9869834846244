import React, {Component, useEffect, useRef} from "react";
import Util from "../../../../Util/Util";
import Config from "../../../../Config";
import Backdrop from "@mui/material/Backdrop";
import {Box, Button, CssBaseline, Divider, FormControl, Grid, MenuItem, Modal, Select} from "@mui/material";
import Canvas from "./Canvas";
import Constants from "../../../../Util/Constants";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    outline: 0,
    width: '100%',
    maxWidth: '700px',
    gridTemplateColumns: {md: '1fr 1fr'},
    gap: 2,
};

export default class GraphModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consults: [],
            loading: false,
            error: false,
            firstOpened: true,
            graph: '',
            graphs: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {patient} = this.props;
        if (patient === undefined || !this.state.firstOpened)
            return;

        if (prevProps.patient === undefined || (this.props.open && this.state.firstOpened)) {
            this.setState({loading: true, firstOpened: false});
            Util.loadData(Config.LOAD_GRAPHS_API + patient.id, result => {
                this.setState({graphs: result.data, loading: false});
                console.log(JSON.stringify(result.data));
            });
        }
    }

    render() {
        let {open, patient} = this.props;
        let {loading, graph, graphs} = this.state;

        let graphOptions = Constants.GRAPH_IMAGES.filter(i => i.sex === patient.sex).map((graphImage) => {
            return <MenuItem value={graphImage} key={graphImage.name}>{graphImage.name}</MenuItem>
        });

        return (
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
                   onClose={this.onClose} closeAfterTransition BackdropComponent={Backdrop}
                   BackdropProps={{timeout: 500}}>
                <Box sx={style}>
                    <CssBaseline/>
                    <Box sx={{display: 'flex', flexDirection: 'row', pb: 2, justifyContent: 'center'}}>
                        {patient.name + " - " + patient.fullAge}
                    </Box>
                    <Divider/>
                    {loading &&
                        Util.renderLoading()
                    }
                    {!loading &&
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Canvas graph={graph}
                                            graphs={graphs}
                                            style={{
                                                display: 'block',
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                width: graph.width,
                                                height: graph.height
                                            }}/>
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                <FormControl fullWidth margin="normal" size="small" sx={{mt: 'auto', mb: 'auto'}}>
                                    <Select id="graph" value={graph}
                                            onChange={(e) => {
                                                this.setState({graph: e.target.value});
                                            }}>
                                        {graphOptions}
                                    </Select>
                                </FormControl>
                                <Box sx={{flex: '1 1 auto'}}/>
                                <Button color="error" onClick={this.onClose} sx={{ml: 1}}>
                                    Cancelar
                                </Button>
                            </Box>
                        </React.Fragment>
                    }
                </Box>
            </Modal>
        )
    }

    onClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;

        this.setState({consults: [], firstOpened: true, graph: ''});
        this.props.closeModal();
    }
}