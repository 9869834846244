export default class Constants {
    static FAMILY = "family";
    static JUST_BORN = "just_born";

    static BACKGROUND_NAMES = [
        {key: 'justBornAsthma', value: 'Asma'},
        {key: 'justBornAlergy', value: 'Alergia'},
        {key: 'otitis', value: 'Otitis'},
        {key: 'convulsions', value: 'Neurologicos'},
        {key: 'pneumonia', value: 'Neumonias'},
        {key: 'mumps', value: 'Varicela'},
        {key: 'urinalInfection', value: 'Inf. Urinarias'},
        {key: 'dbt', value: 'Diabetes'},
        {key: 'measles', value: 'Hemato Oncologicos'},
        {key: 'rubeola', value: 'Digestivos'},
    ];

    static FAMILIAR_BACKGROUNDS = [
        {key: 'dbtPers', value: 'Diabetes'},
        {key: 'psicologicalPers', value: 'Epilepsia'},
        {key: 'athsma', value: 'Asma'},
        {key: 'obesity', value: 'Obesidad'},
        {key: 'alergy', value: 'Alergia'},
        {key: 'migrane', value: 'Migraña'},
        {key: 'tobacco', value: 'Tabaquismo'},
        {key: 'cancer', value: 'Cancer'},
        {key: 'hta', value: 'HTA'},
        {key: 'myopia', value: 'Miopia'},
        {key: 'cardiac_problems', value: 'Problemas cardiacos'},
    ]


// 2 - female, 1 - male
    static GRAPH_IMAGES = [
        //Female
        {name: 'Perimetro Cefalico 0-18 Años', src: 'female-cefperim-graph.png', sex: 2, width: 490, height: 430},
        {name: 'Estatura 0-6 Años', src: 'female-height-to6years.png', sex: 2, width: 645, height: 420},
        {name: 'Estatura 0-19 Años', src: 'female-height-graph.png', sex: 2, width: 492, height: 487},
        {name: 'Peso 0-6 Años', src: 'female-weight-to6years.png', sex: 2, width: 647, height: 417},
        {name: 'Peso 0-19 Años', src: 'female-weight-graph.png', sex: 2, width: 516, height: 553},
        {name: 'IMC 0-5 Años', src: 'female-imc.png', sex: 2, width: 650, height: 467},
        {name: 'IMC 5-19 Años', src: 'female-imc-upto19.png', sex: 2, width: 621, height: 466},

        //Male
        {name: 'Perimetro Cefalico 0-18 Años', src: 'male-cefperim-graph.png', sex: 1, width: 492, height: 411},
        {name: 'Estatura 0-6 Años', src: 'male-height-to6years.png', sex: 1, width: 670, height: 436},
        {name: 'Estatura 0-19 Años', src: 'male-height-graph.png', sex: 1, width: 490, height: 486},
        {name: 'IMC 0-5 Años', src: 'male-imc.png', sex: 1, width: 643, height: 467},
        {name: 'IMC 5-19 Años', src: 'male-imc-upto19.png', sex: 1, width: 632, height: 470},
        {name: 'Peso 0-6 Años', src: 'male-weight-up6years.png', sex: 1, width: 656, height: 418},
        {name: 'Peso 0-19 Años', src: 'male-weight-graph.png', sex: 1, width: 486, height: 559},
    ]
}