import React, {Component} from "react";
import "./Login.css"
import axios from "axios";
import Config from '../Config'
import {Box, Button, Container, createTheme, CssBaseline, TextField, ThemeProvider} from "@mui/material";

const theme = createTheme();

export default class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			logging: false,
			logged: false,
			loginButtonEnabled: true,
			showError: false,
			error: ""
		}

		this.handleClick = this.handleClick.bind(this);
		this.updateInputValue = this.updateInputValue.bind(this);
	}

	render() {
		let {showError, error, username, password, loginButtonEnabled} = this.state;
		if (error !== "")
			return <div>Backend service is not working</div>

		return (
			<ThemeProvider theme={theme}>
				<Container maxWidth="xs">
					<CssBaseline/>
					<Box sx={{marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center"}}>
						<h2 className="text-center">Login</h2>
						{showError &&
						<span className="badge bg-danger" id="invalid-credentials-span">
						Invalid username or password.
						</span>
						}
						<Box component="form" onSubmit={this.handleClick} noValidate sx={{mt: 1}}>
							<TextField id="Username" variant="outlined" label="Usuario" value={username}
							           autoFocus={true} margin="normal" fullWidth
							           onChange={this.updateInputValue} required={true} size={"small"}/>
							<TextField id="Password" type="password" label="Contraseña" variant="outlined"
							           value={password} margin="normal" fullWidth
							           onChange={this.updateInputValue} required={true} size={"small"}/>
							<Button type="submit" id="login-button" fullWidth variant="contained"
							        disabled={!loginButtonEnabled} sx={{mt: 3, mb: 2}}>Login</Button>
						</Box>
					</Box>
				</Container>
			</ThemeProvider>
		);
	}

	updateInputValue(evt) {
		this.setState({showError: false});
		this.setState({
			username: evt.target.id === "Username" ? evt.target.value : this.state.username,
			password: evt.target.id === "Password" ? evt.target.value : this.state.password
		});
	}

	handleClick(evt) {
		evt.preventDefault();
		if (!this.state.loginButtonEnabled)
			return;

		this.tryLogin();
	}

	tryLogin() {
		this.setState({loginButtonEnabled: false, logging: true});
		try {
			axios({
				method: "post",
				url: Config.LOGIN_API,
				withCredentials: true,
				credentials: 'include',
				headers: Config.HEADERS,
				params: {
					username: this.state.username,
					password: this.state.password
				}
			}).then(res => {
				this.setState({logging: false, username: '', password: ''});
				if (res.status === 200) {
					this.setState({logged: true});
					this.props.loginHandler(true);
				}
				if (res.status === 401)
					this.setState({
						logged: false, loginButtonEnabled: true, showError: true, username: '', password: ''
					});
			}).catch(error => {
				if (error.message && error.message.includes("401"))
					this.setState({
						logged: false, loginButtonEnabled: true, showError: true, username: '', password: ''
					});
				if (error.response && error.response.status === 404)
					this.setState({error: "Service is not working"});
			});
		} catch (error) {
			console.error("Error: " + error);
		}
	}
}