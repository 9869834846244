//let backendUrl = "http://127.0.0.1:8080";
let backendUrl = "https://pediatria.ldeazevedo.com:8081"
let restBaseUrl = backendUrl + "/rest";

let pageRequest = "?page=0&size=50";

export default class Config {
    static LOGOUT_API = backendUrl + "/logout";
    static LOGIN_API = backendUrl + "/login";

    static LOAD_PATIENTS_API = restBaseUrl + "/patient/all" + pageRequest
    static SEARCH_PATIENTS_API = restBaseUrl + "/patient/search/%text%" + pageRequest;
    static SAVE_PATIENT_API = restBaseUrl + "/patient/save";

    static LOAD_CONSULT_API = restBaseUrl + "/consult/";
    static SAVE_CONSULTS_API = restBaseUrl + "/consult/save";

    static LOAD_GRAPHS_API = restBaseUrl + "/graph/";

    static HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    static AXIOS_CONFIG = {
        withCredentials: true,
        headers: Config.HEADERS
    };
}